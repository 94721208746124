<template>
  <ul class="case-list">
    <li v-for="(item,index) in list" :key="index">
      <div class="item" :class="{'show-detail': showDetail&&item.desp!==''&&active===index}"
           @click="handlerClick(index)">
        <div class="img-wrap">
          <img :src="require(`@/assets/images/caseLogo/${item.logo}.png`)">
        </div>
        <div class="item-title">
          <span>{{ item.english }}</span>
          <span v-if="lang === 'zh'">{{ item.name }}</span>
        </div>
        <!-- 详情 -->
        <div class="item-detail">
          <div class="name">
            <span>{{ item.english }}</span><span v-if="lang === 'zh'">{{ item.name }}</span>
          </div>
          <div class="desp">
            <span class="text">{{ lang === 'zh' ? item.desp : item.despEN }}</span>
            <!--          <a class="link" v-if="item.link&&" :href="item.link" target="_blank">-->
            <!--            {{item.link}}-->
            <!--            <i class="icon-more" />-->
            <!--          </a>-->
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "CaseList",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showDetail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: -1
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  methods: {
    handlerClick(index) {
      if (this.active === index) {
        this.restCheck()
        return
      }
      this.active = index;
      this.$emit('check', index)
    },
    restCheck() {
      this.active = -1;
    }
  }
}
</script>

<style lang="scss" scoped>
.case-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -23px;
  margin-right: -23px;

  > li {
    width: 20%;

    .item {
      position: relative;
      margin-left: 23px;
      margin-right: 23px;
      margin-bottom: 41px;

      .img-wrap {
        display: flex;
        height: 140px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        align-items: center;
        margin-bottom: 9px;
        transition: all 0.5s;
        cursor: pointer;

        > img {
          display: block;
          //max-width: 100%;
          width: 246px;
          margin: 0 auto;
          filter: grayscale(100%);
          transition: all 0.5s;
          opacity: 0.9;
        }
      }

      .item-title {
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        color: #231916;
        line-height: 32px;
        transition: all 0.5s;

        > span {
          margin-left: 4px;
          margin-right: 4px;
        }
      }

      .item-detail {
        padding-top: 15px;
        display: none;
        position: absolute;
        left: 0px;
        right: 0px;

        .name {
          font-weight: 600;
          font-size: 20px;
          color: #231916;
          line-height: 32px;
          white-space: nowrap;

          > span {
            margin-right: 5px;
          }
        }

        .desp {
          display: flex;
          line-height: 32px;
          justify-content: space-between;

          .text {
            white-space: nowrap;
            border-top: 1px solid #C8171E;
            font-size: 16px;
            color: #231916;
          }

          .link {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #C8171E;
            line-height: 32px;

            .icon-more {
              display: block;
              width: 12px;
              height: 16px;
              margin-left: 11px;
              background: url("~@/assets/images/icon-arrow-more.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }

      &:hover {
        .img-wrap {
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);

          > img {
            filter: grayscale(0%);
            transition: all 0.5s;
            opacity: 1;
          }
        }

        .item-title {
          font-weight: 600;
          color: #C8171E;
        }

      }

      &.show-detail {
        margin-bottom: 100px;

        .item-detail {
          display: block;
        }
      }
    }

  }
}
</style>
