export const CaseList = [
    {
        id: 1,
        name: "犀能新能源",
        english: "XNERGY",
        logo: "case-logo-01",
        type: 1,
        desp: "全球大功率无线充电解决方案领跑者。",
        despEN: "The global leader of high-power wireless charging solutions.",
        link: "https://www.xnergytech.com/",
    },
    {
        id: 2,
        name: "索迩电子",
        english: "THOR",
        logo: "case-logo-02",
        type: 1,
        desp: "全球触觉与创新声学技术引领者。",
        despEN: "The global leader in innovative haptic and acoustic technology.",
        link: "https://www.thorinnovation.com/",
    },
    {
        id: 3,
        name: "白盒子",
        english: "O-Cubes",
        logo: "case-logo-03",
        type: 1,
        desp: "卫星通讯领域的高端芯片公司。",
        despEN: "A high-end satellite communication chip company.",
        link: "http://www.o-cubes.com/",
    },
    {
        id: 4,
        name: "弗德莱博",
        english: "MOODLES",
        logo: "case-logo-04",
        type: 1,
        desp: "全新智造概念的食品科技公司。",
        despEN: "A food technology company with innovative intelligent manufacturing.",
        link: "https://www.fooodlab.com/",
    },
    {
        id: 5,
        name: "奥梭格纳",
        english: "ORTHOGONAL",
        logo: "case-logo-05",
        type: 1,
        desp: "工业仿真软件PaaS平台。",
        despEN: "Disruptive PaaS of industrial simulation.",
        link: "",
    },
    {
        id: 6,
        name: "喔趣科技",
        english: "WorkTrans",
        logo: "case-logo-06",
        type: 1,
        desp: "科技驱动的人力高新技术企业。",
        despEN: "A technology-driven human resources company.",
        link: "https://www.woqu365.com/",
    },
    {
        id: 7,
        name: "深聪智能",
        english: "ShensiliCon",
        logo: "case-logo-07",
        type: 1,
        desp: "AI语音芯片研发及服务商。",
        despEN: "Advanced AI chip development company.",
        link: "http://www.smartic-ai.com/",
    },
    {
        id: 8,
        name: "云生集团",
        english: "Yunsheng Group",
        logo: "case-logo-08",
        type: 1,
        desp: "国内最大的线上人力资源集团。",
        despEN: "The largest online human resources group in China.",
        link: "https://www.ysinc.com/",
    },
    {
        id: 9,
        name: "",
        english: "XTIA",
        logo: "case-logo-09",
        type: 1,
        desp: "创立于日本的“光学频率梳”研发科技公司。",
        despEN: "A Japanese deep-tech company that specializes in \"optical frequency comb technology\"",
        link: "",
    },
    {
        id: 10,
        name: "",
        english: "MIGHTY JAXX",
        logo: "case-logo-10",
        type: 2,
        desp: "全球顶级潮流文化消费品牌。",
        despEN:"The top global pop culture consumer brand.",
        link: "https://www.mightyjaxx.com/",
    },
    {
        id: 11,
        name: "木槿",
        english: "MUMU",
        logo: "case-logo-11",
        type: 2,
        desp: "风靡全球的快时尚百货品牌。",
        despEN: "A globally popular fast fashion department store brand.",
        link: "https://www.mumuso.com/",
    },
    {
        id: 12,
        name: "月枫堂",
        english: "GEPPUDO",
        logo: "case-logo-12",
        type: 2,
        desp: "精致糕点烘焙品牌。",
        despEN: "A refined confectionery baking brand.",
        link: "",
    },
    {
        id: 13,
        name: "悦瞳",
        english: "WOSADO",
        logo: "case-logo-13",
        type: 2,
        desp: "软磁假睫毛开创者。",
        despEN: "An eye makeup brand specializing in magnetic artificial eyelashes.",
        link: "http://wosado.com/",
    },

    {
        id: 14,
        name: "御梵",
        english: "YFF Cosmetics",
        logo: "case-logo-14",
        type: 2,
        desp: "专业化妆品OEM、ODM企业。",
        despEN: "Professional cosmetics OEM and ODM enterprise.",
        link: "http://yff-cosmetics.com/",
    },
    {
        id: 15,
        name: "构美",
        english: "GOUMEE",
        logo: "case-logo-15",
        type: 2,
        desp: "技术驱动的新流量服务商。",
        despEN: "A technology-driven internet service provider.",
        link: "https://www.goumee.com/",
    },
    {
        id: 16,
        name: "超级饰",
        english: "ACC",
        logo: "case-logo-16",
        type: 2,
        desp: "会员制快时尚饰品品牌。 ",
        despEN:"Membership-based fast fashion jewelry brand.",
        link: "",
    },
    {
        id: 17,
        name: "保利沃利",
        english: "PolyVoly",
        logo: "case-logo-17",
        type: 2,
        desp: "美妆个护行业的多品牌管理公司。",
        despEN: "A multi-brand management company in the beauty and personal care industry.",
        link: "",
    },
    {
        id: 18,
        name: "",
        english: "WERISE",
        logo: "case-logo-18",
        type: 3,
        desp: "",
        link: "",
    },
    {
        id: 19,
        name: "",
        english: "CREMA",
        logo: "case-logo-19",
        type: 3,
        desp: "",
        link: "",
    },
    {
        id: 20,
        name: "",
        english: "ESPORTS LAND",
        logo: "case-logo-20",
        type: 3,
        desp: "",
        link: "",
    },

    {
        id: 21,
        name: "",
        english: "The 9 (NASDAQ: NCTY)",
        logo: "case-logo-21",
        type: 3,
        desp: "",
        link: "",
    },

    {
        id: 22,
        name: "",
        english: "SIMSPORTS",
        logo: "case-logo-22",
        type: 3,
        desp: "",
        link: "",
    },
    {
        id: 23,
        name: "",
        english: "FINAL SPARK",
        logo: "case-logo-23",
        type: 3,
        desp: "",
        link: "",
    },
    {
        id: 24,
        name: "",
        english: "METACENE",
        logo: "case-logo-24",
        type: 3,
        desp: "",
        link: "",
    },
]

export const CaseKJ =CaseList.filter((item)=>item.type===1)

export const CaseXF =CaseList.filter((item)=>item.type===2)

export const CaseYX =CaseList.filter((item)=>item.type===3)
